















































































































































































































































































































.main_results_wrapper {
  .el-form-item__label {
    width: 100%;
    text-align: initial;
  }
}
